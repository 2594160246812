<template>
  <div>
    <p>Vous pouvez consulter ou partager des documents avec votre patient depuis cet espace.</p>
    <p>Les documents sont directement rattachés à la téléconsultation et au dossier patient.</p>
    <SharedFiles
      ref="sharedFiles"
      :appointment-id="appointmentId"
      :patient-id="patientId"
      @upload="handleUploadedFile"
    />
  </div>
</template>

<script>
  import SectionTitle from '../../SectionTitle';
  import SharedFiles from '../../SharedFiles';

  export default {
    name: 'FilesPanel',
    components: { SharedFiles, SectionTitle },
    props: {
      appointmentId: {
        type: String,
        required: true,
      },
      patientId: {
        type: String,
        required: true,
      },
    },
    async mounted() {
      this.$store.dispatch('sdkAddEvent', { name: '#UPLOADED_FILE', callback: this.onUploadedFile })
    },
    beforeDestroy() {
      this.$store.dispatch('sdkRemoveEvent', { name: '#UPLOADED_FILE', callback: this.onUploadedFile })
    },
    methods: {
      onUploadedFile() {
        this.$refs.sharedFiles.reloadFiles();
      },
      handleUploadedFile() {
        this.$store.dispatch('sdkEmitRemoteEvent', { name: '#UPLOADED_FILE' });
      },
    },
  };
</script>
