var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "control-bar" },
    [
      _c(
        "div",
        {
          staticClass: "control",
          class: { muted: _vm.microphoneMuted },
          on: { click: _vm.toggleMicrophone }
        },
        [_c("FontAwesomeIcon", { attrs: { icon: _vm.microphoneIcon } })],
        1
      ),
      _c("AppConfirmation", {
        ref: "terminateEncounter",
        attrs: {
          title: "Êtes-vous sûr de vouloir mettre fin à cette consultation ?"
        },
        on: { confirm: _vm.terminate }
      }),
      _c(
        "div",
        {
          staticClass: "control encounter-stop",
          on: {
            click: function($event) {
              return _vm.$confirm(_vm.$refs.terminateEncounter)
            }
          }
        },
        [_c("FontAwesomeIcon", { attrs: { icon: _vm.$icons.encounterStop } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "control",
          class: { muted: _vm.cameraMuted },
          on: { click: _vm.toggleCamera }
        },
        [_c("FontAwesomeIcon", { attrs: { icon: _vm.cameraIcon } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "control",
          on: {
            click: function($event) {
              return _vm.$emit("capture")
            }
          }
        },
        [
          _c("FontAwesomeIcon", {
            attrs: {
              icon: _vm.capturing ? _vm.$icons.spinner : _vm.$icons.camera,
              spin: _vm.capturing
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "control" },
        [_c("ChatIcon", { attrs: { small: "" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "control",
          class: _vm.isPanelOpen ? "active" : "",
          on: {
            click: function($event) {
              return _vm.$emit("togglePanel")
            }
          }
        },
        [
          _c("FontAwesomeIcon", {
            attrs: { icon: _vm.$icons.encounterPanelToggle }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }