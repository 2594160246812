var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calling-interface" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$media.desktop || !_vm.isPanelVisible,
              expression: "$media.desktop || !isPanelVisible"
            }
          ],
          staticClass: "video-container",
          class: { "full-width": !_vm.isPanelVisible }
        },
        [
          _c("div", { staticClass: "cameras" }, [
            _c("div", { staticClass: "consultation-camera-remote" }, [
              _vm.remoteVideoStream
                ? _c("video", {
                    ref: "remoteStream",
                    attrs: { autoplay: "" },
                    domProps: { srcObject: _vm.remoteVideoStream }
                  })
                : _vm._e(),
              _vm.remoteAudioStream
                ? _c("audio", {
                    attrs: { autoplay: "" },
                    domProps: { srcObject: _vm.remoteAudioStream }
                  })
                : _vm._e()
            ]),
            _c("div", { staticClass: "consultation-camera-local" }, [
              _vm.localVideoStream
                ? _c("video", {
                    attrs: { autoplay: "" },
                    domProps: { srcObject: _vm.localVideoStream }
                  })
                : _vm._e(),
              _vm.localAudioStream
                ? _c("audio", {
                    attrs: { autoplay: "" },
                    domProps: { srcObject: _vm.localAudioStream }
                  })
                : _vm._e()
            ])
          ]),
          _c("MediaMessageOverlay"),
          _c("ControlBar", {
            attrs: {
              capturing: _vm.capturing,
              "is-panel-open": _vm.isPanelVisible
            },
            on: {
              capture: _vm.capture,
              togglePanel: function($event) {
                _vm.isPanelVisible = !_vm.isPanelVisible
              }
            }
          })
        ],
        1
      ),
      _vm.isPanelVisible
        ? _c("Panel", {
            attrs: {
              opened: _vm.isPanelVisible,
              encounter: _vm.encounter,
              "appointment-id": _vm.appointmentId,
              patient: _vm.patient
            },
            on: {
              close: function($event) {
                _vm.isPanelVisible = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }