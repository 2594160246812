<template>
  <div :class="{ online: isRemoteOnline, 'has-unread': hasUnreadMessage, small }" class="chat-icon" @click="openChat">
    <FontAwesomeIcon :icon="$icons.chat" class="icon" />
    <span v-show="hasUnreadMessage" class="unread">
      {{ unreadMessagesCount }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'ChatIcon',
    props: {
      small: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isRemoteOnline() {
        return this.$store.state.chat.isRemoteOnline;
      },
      unreadMessagesCount() {
        return this.$store.state.chat.unreadMessagesCount;
      },
      hasUnreadMessage() {
        return this.unreadMessagesCount > 0;
      },
    },
    methods: {
      openChat() {
        this.$store.dispatch('chatOpen');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chat-icon {
    position: relative;
    font-size: 6rem;
    cursor: pointer;

    &.small {
      font-size: 2.5rem;
    }

    &.has-unread {
      animation: beat 5s infinite ease-in-out;
    }

    .icon {
      color: white;
    }

    &::after {
      content: " ";
      position: absolute;
      top: 0.6rem;
      right: 0;
      background-color: $errorColor;
      width: 2rem;
      height: 2rem;
      border-radius: 1rem;
      border: 0.3rem solid white;
    }

    &.small::after {
      top: 0;
      width: 1rem;
      height: 1rem;
      border-radius: 0.5rem;
      border-width: 0.1rem;
    }

    &.online::after {
      background-color: $successColor;
    }
  }

  .unread {
    display: inline-flex;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0.2rem;
    right: 0;
    font-size: 1.7rem;
    background-color: $errorColor;
    color: white;
    border: 0.2rem solid white;
    border-radius: 1rem;

    .small & {
      font-size: 1.3rem;
      width: 1.5rem;
      height: 1.5rem;
      border-width: 0.1rem;
      border-radius: 0.5rem;
      right: -0.3rem;
      bottom: 0;
    }
  }
</style>
