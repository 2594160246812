var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "consultation-toolbar" },
    [
      _vm.timer
        ? _c("AppTimer", { attrs: { short: !_vm.$media.desktop } })
        : _vm._e(),
      _vm.mediaSelector ? _c("MediaSelector") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }