var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enabled
    ? _c("div", { staticClass: "media-selector" }, [
        _vm.hasMultipleAudioDevices
          ? _c(
              "div",
              { staticClass: "media-selector-item" },
              [
                _c(
                  "label",
                  {
                    attrs: {
                      "aria-label": "Choix du microphone",
                      for: "audio-device"
                    }
                  },
                  [
                    _c("FontAwesomeIcon", {
                      attrs: { icon: _vm.$icons.microphone }
                    })
                  ],
                  1
                ),
                _c("SelectWrapper", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.audioDeviceId,
                          expression: "audioDeviceId"
                        }
                      ],
                      attrs: {
                        id: "audio-device",
                        "aria-labelledby": "audio-device"
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.audioDeviceId = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.audioDevices, function(audioDevice, index) {
                      return _c(
                        "option",
                        { domProps: { value: audioDevice.deviceId } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                audioDevice.label || "Micro #" + (index + 1)
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.hasMultipleVideoDevices
          ? _c(
              "div",
              { staticClass: "media-selector-item" },
              [
                _c(
                  "label",
                  {
                    attrs: {
                      "aria-label": "Choix de la caméra",
                      for: "video-device"
                    }
                  },
                  [
                    _c("FontAwesomeIcon", {
                      attrs: { icon: _vm.$icons.camera }
                    })
                  ],
                  1
                ),
                _c("SelectWrapper", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.videoDeviceId,
                          expression: "videoDeviceId"
                        }
                      ],
                      attrs: {
                        id: "video-device",
                        "aria-labelledby": "video-device"
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.videoDeviceId = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.videoDevices, function(videoDevice, index) {
                      return _c(
                        "option",
                        { domProps: { value: videoDevice.deviceId } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                videoDevice.label || "Camera #" + (index + 1)
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }