var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-panel" },
    [
      _c("EditProfil", { attrs: { patient: _vm.patient, picture: false } }),
      _c("hr"),
      _c("Observations", { attrs: { patient: _vm.patient } }),
      _c("hr"),
      _c("GroupTitle", { attrs: { icon: _vm.$icons.report } }, [
        _vm._v("Compte-rendu ")
      ]),
      _c("p", [
        _vm._v(
          "\n    Inutile d’attendre la fin de la téléconsultation pour commencer à rédiger votre compte-rendu ou à établir une\n    prescription médicamenteuse.\n  "
        )
      ]),
      _c(
        "FormWidget",
        {
          scopedSlots: _vm._u([
            {
              key: "help",
              fn: function() {
                return [
                  _vm._v(
                    "\n      Ce champ est uniquement visible par les professionnels de santé.\n      Le patient n'aura pas accès à cette information.\n    "
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("label", { attrs: { for: "medical-observation" } }, [
            _vm._v("Observation médicale")
          ]),
          _c("AutoHeightTextarea", {
            attrs: { id: "medical-observation" },
            model: {
              value: _vm.form.medicalObservation,
              callback: function($$v) {
                _vm.$set(_vm.form, "medicalObservation", $$v)
              },
              expression: "form.medicalObservation"
            }
          })
        ],
        1
      ),
      _c(
        "FormWidget",
        [
          _c("label", { attrs: { for: "patient-advice" } }, [
            _vm._v("Conseils au patient")
          ]),
          _c("AutoHeightTextarea", {
            attrs: { id: "patient-advice" },
            model: {
              value: _vm.form.patientAdvice,
              callback: function($$v) {
                _vm.$set(_vm.form, "patientAdvice", $$v)
              },
              expression: "form.patientAdvice"
            }
          })
        ],
        1
      ),
      !_vm.isNurse
        ? _c("PrescriptionModule", {
            attrs: {
              "encounter-id": _vm.encounterId,
              "existing-prescriptions": _vm.prescriptions,
              "birth-date": this.beneficiary.birthDate
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }