<!--
Emitted events:
- openPanel
- capture
-->
<template>
  <div class="control-bar">
    <div :class="{ muted: microphoneMuted }" class="control" @click="toggleMicrophone">
      <FontAwesomeIcon :icon="microphoneIcon" />
    </div>
    <AppConfirmation
      ref="terminateEncounter"
      title="Êtes-vous sûr de vouloir mettre fin à cette consultation ?"
      @confirm="terminate"
    />
    <div class="control encounter-stop" @click="$confirm($refs.terminateEncounter)">
      <FontAwesomeIcon :icon="$icons.encounterStop" />
    </div>

    <div :class="{ muted: cameraMuted }" class="control" @click="toggleCamera">
      <FontAwesomeIcon :icon="cameraIcon" />
    </div>
    <div class="control" @click="$emit('capture')">
      <FontAwesomeIcon
        :icon="capturing ? $icons.spinner : $icons.camera"
        :spin="capturing"
      />
    </div>
    <div class="control">
      <ChatIcon small />
    </div>
    <div :class="isPanelOpen ? 'active' : ''" class="control" @click="$emit('togglePanel')">
      <FontAwesomeIcon :icon="$icons.encounterPanelToggle" />
    </div>
  </div>
</template>

<script>
  import AppConfirmation from '@/components/AppConfirmation';
  import ChatIcon from '@/components/Chat/ChatIcon';

  export default {
    name: 'ControlBar',
    components: { AppConfirmation, ChatIcon },
    props: {
      capturing: {
        type: Boolean,
        default: false,
      },
      isPanelOpen: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      microphoneMuted() {
        return !this.$store.state.sdk.local.audio.enabled;
      },
      cameraMuted() {
        return !this.$store.state.sdk.local.video.enabled;
      },
      microphoneIcon() {
        return this.microphoneMuted
          ? this.$icons.encounterMicrophoneToggleDisabled
          : this.$icons.encounterMicrophoneToggle;
      },
      cameraIcon() {
        return this.cameraMuted
          ? this.$icons.encounterCameraToggleDisabled
          : this.$icons.encounterCameraToggle;
      },
    },
    methods: {
      toggleMicrophone() {
        this.$store.dispatch('sdkToggleAudioDevice');
      },
      toggleCamera() {
        this.$store.dispatch('sdkToggleVideoDevice');
      },
      async terminate() {
        await this.$store.dispatch('sdkTerminate');
      },
      openChat() {
        this.$store.dispatch('chatOpen');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .control-bar {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem 2rem;
    background-color: #1F1F1FA0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #F1F1F1;
    border-radius: 3.5rem;
    width: 100%;
    max-width: 580px;
    @include onMobile {
      justify-content: space-around;
      width: 80%;
      max-width: 490px;
    }
  }

  .control {
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    cursor: pointer;
    border-radius: 50%;
    @include onMobile {
      width: 0;
    }

    &:hover {
      background-color: #1F1F1F80;
    }

    &.muted, &.active {
      color: $cancelButtonColor;
    }
  }

  .encounter-stop {
    font-size: 2rem;
    color: #FFFFFF;
    background-color: $cancelButtonColor;

    &:hover {
      background-color: lighten($cancelButtonColor, 5%);
    }
  }
</style>
