<template>
  <AppLayout :footer="false" :header="false">
    <main v-if="isLoaded" class="consultation-page fullscreen">
      <ConsultationToolbar :media-selector="isStepCalling || isStepWaitingRoom" :timer="isStepCalling" />
      <ErrorInterface v-if="isStepError" :error-code="errorCode">
        {{ errorMessage }}
      </ErrorInterface>
      <ErrorInterface v-else-if="!isNavigatorOnline" error-code="NAVIGATOR_OFFLINE" title="Aucune connexion internet">
        En attente de reconnexion...
      </ErrorInterface>
      <PostCallingInterface
        v-else-if="isStepPostCalling"
        :appointment-id="appointmentId"
      />
      <AppLoader v-else-if="isStepTerminating">
        Arrêt de la consultation en cours...
      </AppLoader>
      <AppLoader v-else-if="isStepJoining">
        Démarrage de la consultation en cours...
      </AppLoader>
      <PreCallingInterface
        v-else-if="isStepWaitingRoom"
        :patient-full-name="patientFullName"
        :patient-phone-number="patientPhoneNumber"
        :practitioner-full-name="practitionerFullName"
      />
      <CallingInterface
        v-else-if="isStepCalling"
        :appointment-id="appointmentId"
        :encounter="encounter"
        :patient="appointment.patient"
        :patient-id="patientId"
      />
      <AppLoader v-else>
        Chargement en cours...
      </AppLoader>
    </main>

    <template v-if="isChatAvailable">
      <ChatIcon v-if="!isStepCalling" />

      <AppPanel :opened="isChatOpened" @close="closeChat">
        <SectionTitle>Chat</SectionTitle>
        <Chat :appointment-id="appointmentId" :remote-username="patientFullName" />
        <div v-show="$media.panel && isChatOpened" class="consultation-camera-local draggable">
            <video v-if="localVideoStream" autoplay :srcObject.prop="remoteVideoStream" />
        </div>
      </AppPanel>
    </template>

    <AppConfirmation
      ref="leaveConsultation"
      title="Souhaitez-vous quitter la téléconsultation ? Vous pourrez reprendre celle-ci plus tard si vous le désirez."
      @confirm="(next) => next()"
    />
  </AppLayout>
</template>

<script>
  import AppConfirmation from '@/components/AppConfirmation';
  import AppLayout from '@/components/AppLayout';
  import AppLoader from '@/components/AppLoader';
  import AppLogo from '@/components/AppLogo';
  import AppPanel from '@/components/AppPanel';
  import AppTimer from '@/components/AppTimer';
  import Chat from '@/components/Chat/Chat';
  import ChatIcon from '@/components/Chat/ChatIcon';
  import CallingInterface from '@/components/Consultation/CallingInterface';
  import ErrorInterface from '@/components/Consultation/ErrorInterface';
  import MediaSelector from '@/components/Consultation/MediaSelector';
  import PostCallingInterface from '@/components/Consultation/PostCallingInterface';
  import PreCallingInterface from '@/components/Consultation/PreCallingInterface';
  import ConsultationToolbar from '@/components/ConsultationToolbar';
  import SectionTitle from '@/components/SectionTitle';
  import UserAvatar from '@/components/UserAvatar';
  import { STEP } from '@/config/store_modules/sdk';
  import { getPlatformJWT } from '@/helpers/auth';
  import { getSetting } from '@/helpers/tools.js';
  import * as Sentry from '@sentry/browser';
  import Encounters from "@/api/encounters";

  const { STATUS, browserSupported } = window.PlatformSDK;

  export default {
    name: 'ConsultationPage',
    components: {
      AppConfirmation,
      AppLayout,
      AppLoader,
      AppLogo,
      AppPanel,
      AppTimer,
      CallingInterface,
      Chat,
      ChatIcon,
      ConsultationToolbar,
      ErrorInterface,
      MediaSelector,
      PostCallingInterface,
      PreCallingInterface,
      SectionTitle,
      UserAvatar,
    },
    props: {
      appointmentId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isNavigatorOnline: navigator.onLine,
      };
    },
    async beforeMount() {
      window.addEventListener('beforeunload', this.handleBeforeUnload);
      window.addEventListener('unload', this.handleUnload);
      window.addEventListener('online', this.handleOnline);
      window.addEventListener('offline', this.handleOffline);
      await this.$store.dispatch('appointmentsClearCurrent');
      await this.$store.dispatch('encountersClearCurrent');
    },
    async mounted() {
      await this.$store.dispatch('appLoaderShow');

      await this.loadAppointment();

      if (this.appointment.encounters?.[0]) {
        await this.loadEncounter(this.appointment.encounters?.[0].id);
      } else {
        await this.createEncounter();
      }

      if (getSetting('SENTRY_ENABLED')) {
        Sentry.configureScope(scope => scope.setTags({
          appointment_id: this.appointment.id,
          patient_id: this.appointment.patient.id,
          product_id: this.appointment.product.id,
          encounter_id: this.encounter.id,
        }));
      }

      await this.$store.dispatch('appLoaderHide');
    },
    async beforeDestroy() {
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
      window.removeEventListener('unload', this.handleUnload);
      window.removeEventListener('online', this.handleOnline);
      window.removeEventListener('offline', this.handleOffline);

      await this.$store.dispatch('sdkDestroy');
      await this.$store.dispatch('chatLeave');
    },
    beforeRouteLeave(to, from, next) {
      if (!this.isStepCalling || to.meta.ignore) {
        return next();
      }

      this.$confirm(this.$refs.leaveConsultation, next);
    },
    watch: {
      canConnect(canConnect) {
        if (!canConnect) { return; }

        this.connect();
      },
      isChatAvailable: {
        immediate: true,
        async handler(isChatAvailable) {
          await this.$store.dispatch(isChatAvailable ? 'chatJoin' : 'chatLeave', { appointmentId: this.appointmentId });
        },
      },
    },
    computed: {
      isLoaded() { // Has watcher
        return !!this.encounter && !!this.appointment;
      },
      canConnect() {
        return this.isLoaded && this.isNavigatorOnline;
      },
      patientId() { return this.appointment.patient.id; },
      patientFullName() { return this.$store.getters.currentAppointmentPatientFullName; },
      patientPhoneNumber() { return this.$store.state.appointments.current?.patient.phone; },
      practitionerFullName() { return this.$store.getters.currentAppointmentPractitionerFullName; },
      appointment() { return this.$store.state.appointments.current; },
      encounter() { return this.$store.state.encounters.current; },
      status() {
        return this.$store.state.sdk.local.status;
      },
      step() {
        return this.$store.state.sdk.step;
      },
      isStepError() {
        return this.step === STEP.ERROR;
      },
      isStepJoining() {
        return this.status === STATUS.JOINING;
      },
      isStepWaitingRoom() {
        return this.step === STEP.WAITING_ROOM;
      },
      isStepCalling() {
        return this.step === STEP.CALLING;
      },
      isStepTerminating() {
        return this.status === STATUS.TERMINATING;
      },
      isStepPostCalling() {
        return this.step === STEP.POST_CALLING || this.status === STATUS.TERMINATED;
      },
      canJoin() {
        return this.$store.getters.sdkCanJoin;
      },
      isChatAvailable() {
        return this.isStepWaitingRoom || this.isStepError || this.isStepJoining || this.isStepCalling;
      },
      isChatOpened() {
        return this.$store.state.chat.isOpen;
      },
      errorCode() {
        return this.$store.state.sdk.errorCode;
      },
      errorMessage() {
        return this.$store.state.sdk.errorMessage || 'Une erreur inconnue est survenue';
      },
      localVideoStream() {
        return this.$store.state.sdk.local.video.stream;
      },
      remoteVideoStream() {
        return this.$store.state.sdk.remote.video.stream;
      },
    },
    methods: {
      async loadAppointment() {
        await this.$store.dispatch('appointmentsFetchOne', { id: this.appointmentId });

        if (this.appointment.status === 'TERMINATED') {
          this.$addError('Ce rendez-vous a déjà eu lieu. Vous pouvez compléter le rapport de consultation.', true);
          return this.$router.push({ name: 'consultation-report', params: { appointmentId: this.appointmentId } });
        }

        if (['CLOSED', 'PAYMENT_REQUIRED'].includes(this.appointment.status)) {
          this.$addError('Ce rendez-vous a déjà eu lieu', true);
          return this.$router.push({ name: 'home' });
        }
      },
      async loadEncounter(id) {
        try {
          await this.$store.dispatch('appLoaderShow');
          await this.$store.dispatch('encountersClearCurrent');
          await this.$store.dispatch('encountersFetchOne', { id });
          await Encounters.join(id);
        } catch (e) {
          this.$addError('Une erreur est survenue lors du chargement de la consultation');
          throw e;
        } finally {
          await this.$store.dispatch('appLoaderHide');
        }
      },
      async createEncounter() {
        await this.$store.dispatch('encountersCreate', {
          appointmentId: this.appointmentId,
          patientId: this.appointment.patient.id,
          productId: this.appointment.product.id,
        });
      },
      async initSDK() {
        if (!browserSupported) {
          if (this.$isDev) {
            this.$addError('[DEV] Attention, navigateur incompatible. En production, vous seriez redirigé vers une page d\'erreur.');
          } else {
            return this.$router.push({ name: 'incompatible-browser' });
          }
        }

        await this.$store.dispatch('sdkCreateInstance', {
          encounterId: this.encounter.id,
          appointmentId: this.appointmentId,
          apiKey: getSetting('PLATFORM_API_PUBLIC_KEY'),
          apiToken: getPlatformJWT(),
        });

        this.$setTimeout(() => {
          this.$store.dispatch('sdkActivateConsultation');
        }, 60000);
      },
      async connect() {
        await this.initSDK();
        await this.$store.dispatch('sdkConnect');
        this.$setTimeout(() => this.$store.dispatch('sdkEnableNotificationSound'), 8000);
      },
      toggleCamera() {
        this.$store.dispatch('sdkToggleVideoDevice');
      },
      toggleMicrophone() {
        this.$store.dispatch('sdkToggleAudioDevice');
      },
      handleBeforeUnload(event) {
        if (!this.isStepCalling) {
          return;
        }

        event.preventDefault();
        event.returnValue = '';
      },
      async handleUnload() {
        await this.$store.dispatch('sdkDestroy');
      },
      openChat() {
        this.$store.dispatch('chatOpen');
      },
      closeChat() {
        this.$store.dispatch('chatClose');
      },
      handleOnline() {
        this.isNavigatorOnline = true;
      },
      handleOffline() {
        this.isNavigatorOnline = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .main-container .consultation-page.fullscreen {
    display: flex;
    flex-direction: column;
    padding: 0;
    background: $gradientBackground;

    @include onMobile {
      max-height: calc(100vh - 7rem);
    }
  }

  .chat-icon {
    position: absolute;
    top: 15rem;
    right: 2rem;
  }

  .draggable {
    width: 12rem;
    height: 14rem;
    border-radius: 10px;
    float: left;
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    z-index: 2;
  }
  .draggable.is-pointer-down {
    z-index: 2;
  }
  .draggable.is-dragging { opacity: 0.7; }

  .consultation-camera-local {
    right: 1.5rem;
    width: 12rem;
    height: 14rem;
    overflow: hidden;
    border-radius: 0.8rem;
    transform: scale(-1, 1);

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  ::v-deep .panel {
    @media (max-width: 1279px) {
      width: 100vw;
      max-width: none;
      &>.content {
        height: 100vh;
      }
    }
  }
</style>
