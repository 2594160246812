var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat" }, [
    _c(
      "div",
      { staticClass: "remote-status", class: { online: _vm.isRemoteOnline } },
      [
        _vm._v(
          "\n    " +
            _vm._s(_vm.remoteUsername) +
            " " +
            _vm._s(_vm.isRemoteOnline ? "est" : "n'est pas") +
            " connecté\n  "
        )
      ]
    ),
    _c("div", { staticClass: "warning" }, [
      _vm._v(
        "\n    Attention, cette messagerie instantanée ne remplace pas une téléconsultation, elle vous est proposée en cas\n    de difficultés à établir la liaison en vidéo. L'historique de la conversation n'est pas enregistré et ne pourra\n    pas être restauré une fois cette page fermée.\n  "
      )
    ]),
    _c(
      "div",
      { ref: "messages", staticClass: "messages" },
      _vm._l(_vm.messagesGroups, function(messagesGroup) {
        return _c(
          "div",
          { staticClass: "messages-group", class: messagesGroup.type },
          _vm._l(messagesGroup.messages, function(message) {
            return _c("div", { staticClass: "message" }, [
              !message.typing
                ? _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(message.content))
                  ])
                : _c("div", { staticClass: "dots" }, [
                    _c("div", { staticClass: "dot" }),
                    _c("div", { staticClass: "dot" }),
                    _c("div", { staticClass: "dot" })
                  ])
            ])
          }),
          0
        )
      }),
      0
    ),
    _c(
      "form",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isRemoteOnline,
            expression: "isRemoteOnline"
          }
        ],
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.sendMessage($event)
          }
        }
      },
      [
        _c(
          "FormWidget",
          [
            _c("label", { attrs: { for: "chat-message" } }, [
              _vm._v("Votre message")
            ]),
            _c("AutoHeightTextarea", {
              attrs: { id: "chat-message" },
              on: {
                input: _vm.handleInput,
                keypress: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  if (
                    $event.ctrlKey ||
                    $event.shiftKey ||
                    $event.altKey ||
                    $event.metaKey
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.sendMessage($event)
                }
              },
              model: {
                value: _vm.message,
                callback: function($$v) {
                  _vm.message = $$v
                },
                expression: "message"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }