<template>
  <div class="consultation-toolbar">
    <AppTimer v-if="timer" :short="!$media.desktop" />
    <MediaSelector v-if="mediaSelector" />
  </div>
</template>

<script>
  import AppTimer from '@/components/AppTimer';
  import MediaSelector from '@/components/Consultation/MediaSelector';

  export default {
    name: 'ConsultationToolbar',
    components: { AppTimer, MediaSelector },
    props: {
      timer: {
        type: Boolean,
        default: false,
      },
      mediaSelector: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style scoped>
  .consultation-toolbar {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #00000010;
  }

  .app-timer {
    font-size: 1.5rem;
    padding: 1.6rem 2rem;
  }

  .media-selector {
    margin-left: auto;
  }
</style>
