var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pre-calling-interface" },
    [
      _c("SectionTitle", { attrs: { center: "", light: "" } }, [
        _vm.canJoin
          ? _c("span", [
              _vm._v("Vous pouvez dès à présent appeler votre patient")
            ])
          : _c("span", [_vm._v("Votre patient n'est pas encore connecté")])
      ]),
      _c("div", { staticClass: "action-wrapper" }, [
        _c(
          "div",
          { staticClass: "user-card" },
          [
            _c("UserAvatar", {
              attrs: {
                "user-name": _vm.patientFullName,
                src: _vm.patientAvatarUrl
              }
            }),
            _c("span", { staticClass: "full-name" }, [
              _vm._v("\n        " + _vm._s(_vm.patientFullName)),
              _c("br"),
              _vm.patientPhoneNumber
                ? _c("span", { staticClass: "phone-number" }, [
                    _vm._v(_vm._s(_vm._f("phone")(_vm.patientPhoneNumber)))
                  ])
                : _vm._e()
            ])
          ],
          1
        ),
        _c("hr", { staticClass: "link", class: { active: _vm.canJoin } }),
        _c(
          "div",
          {
            staticClass: "button",
            class: { disabled: !_vm.canJoin },
            on: { click: _vm.handleJoin }
          },
          [
            _c("FontAwesomeIcon", {
              attrs: {
                icon: _vm.canJoin
                  ? _vm.$icons.encounterStart
                  : _vm.$icons.encounterStartDisabled
              }
            })
          ],
          1
        ),
        _c("hr", { staticClass: "link", class: { active: _vm.canJoin } }),
        _c("div", { staticClass: "user-card" }, [
          _c("video", {
            staticClass: "user-avatar",
            staticStyle: {
              "object-fit": "cover",
              transform: "rotateY(180deg)"
            },
            attrs: { autoplay: "" },
            domProps: { srcObject: _vm.localVideoStream }
          }),
          _c("span", { staticClass: "full-name" }, [
            _vm._v(_vm._s(_vm.practitionerFullName))
          ])
        ])
      ]),
      _c("div", { staticClass: "text-wrapper" }, [
        _vm.canJoin
          ? _c("div", { staticClass: "patient-is-waiting" }, [
              _vm._v(
                "\n      Votre patient vous attend. Dès que vous êtes prêt, cliquez sur la caméra ci-dessus\n      pour lancer la téléconsultation.\n    "
              )
            ])
          : _vm.hasConsultationOccurred
          ? _c(
              "div",
              { staticClass: "terminate-encounter" },
              [
                _c("AppConfirmation", {
                  ref: "terminateEncounter",
                  attrs: {
                    title:
                      "Êtes-vous sûr de vouloir mettre fin à cette consultation ?"
                  },
                  on: { confirm: _vm.terminate },
                  scopedSlots: _vm._u([
                    {
                      key: "description",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n          Vous serez redirigé vers le rapport de consultation et votre patient ne pourra plus la rejoindre.\n        "
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c(
                  "ButtonWhite",
                  {
                    attrs: { outlined: "", small: "" },
                    on: {
                      click: function($event) {
                        return _vm.$confirm(_vm.$refs.terminateEncounter)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        Vous avez effectué la consultation via une autre méthode ou votre patient s'est déconnecté ?\n        Cliquez ici pour clôturer la consultation\n      "
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c("InfoStatusPayment")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }