<template>
  <div>
    <div class="telemetry-form" v-if="!doc2uStarted">
      <p><strong>Code de télémesure Tesly Cloud pour cette téléconsultation.</strong></p>
      <p>Ce numéro vous sera communiqué par votre patient uniquement si la téléconsultation est effectuée
        auprès d’un pharmacien agréé.</p>
        <input v-model="code" type="text" class="telemetry-input"><ButtonGreen @click="handleSubmit" rounded small>Voir les mesures des patients <FontAwesomeIcon :icon="$icons.chevronRight"/></ButtonGreen>
    </div>
    <ModuleLoader v-if="isLoading" />
    <div v-if="telemetry && !isLoading">
      <div class="telemetry-data" v-if="telemetry.data">
        <p><strong>Données de santé</strong> <span class="tag"><i>{{ lengthTelemetryData }}</i></span></p>
        <template v-for="(data, index) in telemetry.data">
          <div class="biometry-data">
            <img :src="getPathImg(index)" v-bind:alt="index">
            <p class="name-data">{{ getNameBiometryData(index) }}</p>
            <p class="value-data" v-if="!data.secondValue">{{data.value}} {{data.unit}}</p>
            <p class="value-data" v-if="data.secondValue">{{data.value}} / {{data.secondValue}} {{data.unit}}</p>
          </div>
        </template>
      </div>

      <div class="telemetry-data" v-if="telemetry.sounds">
        <p><strong>Sons</strong> <span class="tag"><i>{{ lengthTelemetrySounds }}</i></span></p>
        <ModuleLoader v-if="isLoadingFiles" />
        <template v-else>
          <audio v-for="(sound) in file.sounds" controls class="telemetry-audio">
            <source :src="sound">
          </audio>
        </template>
      </div>

      <div class="telemetry-data" v-if="telemetry.pictures">
        <p><strong>Photos</strong> <span class="tag"><i>{{ lengthTelemetryPictures }}</i></span></p>
        <ModuleLoader v-if="isLoadingFiles" />
        <template v-else>
          <img v-for="(picture, index) in file.pictures" alt="" class="telemetry-picture" v-lazy="picture.src" @click="openGallery(index)">
        </template>
        <LightBox
                :images="file.pictures"
                ref="lightbox"
                :show-caption="true"
                :show-light-box="false"
        />
      </div>

      <div class="telemetry-data" v-if="telemetry.movies">
        <div class="telemetry-video"><p><strong>Vidéos</strong> <span class="tag"><i>{{ lengthTelemetryMovies }}</i></span></p> <ButtonGrey @click="loadMovies" small rounded>Charger les vidéos</ButtonGrey></div>
        <ModuleLoader v-if="isLoadingMovies" />
        <template v-else>
          <video v-for="(movie) in file.movies" controls class="telemetry-movie">
            <source :src="movie">
          </video>
        </template>
      </div>
    </div>

    <!-- DOC2U -->
    <div v-if="encounter.doc2uData.room && !doc2uStarted">
      <div class="telemetry-form">
        <p><strong>Helloscope</strong></p>
        <p>Si votre patient possède un Helloscope ou objet de prise de mesure des constantes compatibles, vous pouvez obtenir ses constantes en cliquant sur le bouton ci-dessous.</p>
        <ButtonGreen @click="toggleDoc2u" rounded small>Lancer la prise de mesure</ButtonGreen>
      </div>
    </div>
    <div v-if="doc2uStarted">
      <div class="container-iframe-top">
        <div v-if="this.$store.state.observations.isFetching" class="container-spinner">
          <Spinner />
        </div>
        <div class="container-iframe__datablock" v-else>
          <div v-if="searchLength>0">
            <LastObservations :search-length="searchLength" :observations="this.$store.state.observations.search"/>
          </div>
          <div v-else>
            <p>Aucune mesure enregistrée.</p>
          </div>
        </div>
      </div>
      <iframe class="doc2u-content" :src="`https://app.doc2u.fr/room?t=${encounter.doc2uData.token}`"></iframe>
      <ButtonRed class="doc2u-button-finish" rounded small @click="toggleDoc2u">Terminer la prise de mesure</ButtonRed>
    </div>
  </div>
</template>
<script>
  import SectionTitle from '../../SectionTitle';
  import ButtonGreen from "../../Button/ButtonGreen.vue";
  import ButtonGrey from "../../Button/ButtonGrey.vue";
  import LightBox from 'vue-image-lightbox';
  import ModuleLoader from '../../ModuleLoader';
  import ButtonRed from "@/components/Button/ButtonRed.vue";
  import Spinner from "../../Spinner";
  import LastObservations from "../../LastObservations";

  export default {
    name: 'FilesPanel',
    components: {ButtonRed, ButtonGrey, ButtonGreen, SectionTitle, LightBox, ModuleLoader, Spinner, LastObservations },
    props: {
      encounter: {
        type: Object,
        required: true,
      },
      patient: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        code: '',
        telemetry: null,
        file: {
          sounds: [],
          pictures: [],
          movies: []
        },
        isLoadingSounds: true,
        isLoadingPictures: true,
        isLoadingMovies: false,
        isLoading: false,
        doc2uStarted: false,
        data: [],
        dataTemperature: [],
        dataOxygen: [],
        dataCardiacRhythm: [],
        dataDiastolicPressure: [],
        dataSystolicPressure: [],
        searchLength: 0
      }
    },
    mounted() {
      if (this.telemetryData) {
        this.telemetry = this.telemetryData;
        if (this.fileData.sounds || this.fileData.pictures || this.fileData.movies ) {
          this.isLoadingFiles = true;
          this.isLoadingMovies = true;
          this.convertDataFiles();
          this.isLoadingFiles = false;
          this.isLoadingMovies = false;
        }
      }
      window.addEventListener("message",  (msg) =>  {
        if (msg.origin === "https://app.doc2u.fr") {
          if(msg.data.source==="oximeterManager") this.dataOxygen = msg.data;
          if(msg.data.source==="temperatureProcess") this.dataTemperature = msg.data;
          if(msg.data.source==="bloodPressureManager") this.dataTemperature = msg.data;
          this.data = {
            "dataTemperature" : this.dataTemperature,
            "dataOxygen" : this.dataOxygen,
            "dataCardiacRhythm" : this.dataCardiacRhythm,
            "dataDiastolicPressure" : this.dataDiastolicPressure,
            "dataSystolicPressure" : this.dataSystolicPressure,
          }
        }
      });
      this.getLastObservations()
    },
    computed: {
      telemetryData() { return this.$store.state.tesly.current; },
      fileData() { return this.$store.state.tesly.file; },
      lengthTelemetryData() {
        return Object.keys(this.telemetry.data).length;
      },
      lengthTelemetrySounds() {
        return Object.keys(this.telemetry.sounds).length;
      },
      lengthTelemetryPictures() {
        return Object.keys(this.telemetry.pictures).length;
      },
      lengthTelemetryMovies() {
        return Object.keys(this.telemetry.movies).length;
      },
    },
    methods: {
      convertDataFiles() {
        for (let [type] of Object.entries(this.fileData)) {
          this.fileData[type].map( file => {
            this.getDataFromBlob(file, type, this.setFile)
          });
        }
      },
      getDataFromBlob(blob, type, callback) {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function() {
          let base64;
          if (type === 'sounds') {
            base64 = reader.result.replace('data:text/html;', 'data:audio/wav;');
          } else if (type === 'pictures') {
            base64 = reader.result.replace('data:text/html;', 'data:image/jpg;');
          } else if (type === 'movies') {
            base64 = reader.result.replace('data:text/html;', 'data:video/mp4;');
          }
          callback(type, base64);
        }
      },
      setFile(type, base64) {
        if (type !== 'pictures') {
          this.file[type].push(base64);
        } else {
          this.file[type].push({
            src: base64,
            thumb: base64
          });
        }
      },
      getPathImg(name) {
        try {
          return require('../../../assets/images/picto_' + name + '.svg');
        } catch {
          return require('../../../assets/images/picto_temperature.svg');
        }
      },
      getNameBiometryData(name) {
        switch (name) {
          case 'temperature':
            return 'Température';
          case 'cardiacFrequency':
            return 'Fréquence cardique';
          case 'weight':
            return 'Poids';
          case 'oxygenSaturation':
            return 'Saturation';
          case 'glucose':
            return 'Glycémie';
          case 'arterialPressure':
            return 'Pression artérielle';
          case 'hemogrobinemy':
            return 'Hémoglobinémie';
          case 'o2PartialPressure' :
            return 'Pression partielle O2';
          case 'co2PartialPressure':
            return 'Pression partielle CO2';
          case 'hematocrit':
            return 'Hématocrite';
          case 'bloodViscosity':
            return 'Viscosité du sang';
          case 'bloodPH':
            return 'pH sanguin';
          case 'cardiacFlow':
            return 'Débit cardiaque';
          case 'averageBleedingVolume':
            return 'Pression artérielle moyenne';
          case 'co2Content':
            return 'Taux CO2';
          case 'redBloodCellCount':
            return 'Taux de globules rouges';
          case 'systolicVolume':
            return 'Volume systolique';
          case 'o2Content':
            return 'Taux O2';
          default:
            return name;
        }
      },
      openGallery(index) {
        this.$refs.lightbox.showImage(index)
      },
      toggleDoc2u() {
        this.doc2uStarted = !this.doc2uStarted;
      },
      async getLastObservations() {
        await this.$store.dispatch('observationsFetch', {
          patient: this.patient.id,
          lastObs: true,
          code: null
        })
        if(this.$store.state.observations.search) {
          this.searchLength = this.$store.state.observations.search.length;
        }
      },
      async handleSubmit() {
        if (this.code) {
          this.isLoading = true;
          this.isLoadingFiles = true;
          this.isLoadingMovies = false;
          this.telemetry = null;
          this.file.sounds = [];
          this.file.pictures = [];
          this.file.movies = [];
          try {
            await this.$store.dispatch('teslyGetData', {
              code: this.code
            });
            this.telemetry = this.telemetryData;
            this.isLoading = false;
          } catch (e) {
            this.$addError('Une erreur s\'est produite lors du chargement des données de santé.');
            throw e;
          } finally {
            this.isLoading = false;
          }

          if (this.telemetry.sounds) {
            for (let filename of this.telemetry.sounds) {
              await this.getFileData(filename, 'sounds');
            }
          }
          if (this.telemetry.pictures) {
            for (let filename of this.telemetry.pictures) {
              await this.getFileData(filename, 'pictures');
            }
          }
          this.convertDataFiles();
          this.isLoadingFiles = false;
        }
      },
      async loadMovies() {
        this.isLoadingMovies = true;
        if (this.code) {
          if (this.telemetry.movies) {
            for (let filename of this.telemetry.movies) {
              await this.getFileData(filename, 'movies');
            }
            this.fileData['movies'].map( file => {
              this.getDataFromBlob(file, 'movies', this.setFile)
            });
            this.isLoadingMovies = false;
          }
        }
      },
      async getFileData(filename, type) {
        await this.$store.dispatch('teslyDownloadFile', {
          code: this.code,
          filename: filename,
          type: type
        });
      },
    },
  };
  require('vue-image-lightbox/dist/vue-image-lightbox.min.css')
</script>
<style lang="scss" scoped>
  .telemetry-input {
    padding: 0 15px;
    margin: 1em 2em 0 0;
    height: 3em;
    width: 35%;
    border: 1px solid $borderColor;
    border-radius: 3px;
  }
  .telemetry-form {
    padding-bottom: 2em;
    border-bottom: 1px solid $borderColor;
  }
  span.tag {
    margin-left: 5px;
    background: #27D7AB;
    padding: 2px 4px;
    border-radius: 3px;
    i {
      margin: 0 auto;
      color: #fff;
      font-weight: bold;
      font-style: normal;
    }
  }
  .telemetry-data {
    padding: 20px 0;
    border-bottom: 1px solid $borderColor;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border: 0;
    }
  }

  .telemetry-video {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;  }

  .telemetry-picture {
    margin: 5px;
    width: 30%;
  }

  .telemetry-load {
    float: right;
  }

  .telemetry-movie, .telemetry-audio {
    margin: 5px;
    width: 100%;
  }

  .biometry-data {
    width: 120px;
    display: inline-block;
    text-align: center;
    margin: 10px 10px;
    .name-data {
      font-size: 10px;
      color: #9B9B9B;
      margin-bottom: 1px;
    }
    .value-data {
      margin: 0;
      font-weight: bold;
      font-size: 15px;
    }
  }

  .doc2u-content {
    border: 1px solid $borderColor;
    width: 100%;
    height: 78vh;
    border-radius: 3px;
  }
  .doc2u-button-finish {
    display: block;
    margin: 1rem auto;
  }
    .container-iframe-top {
      min-height: 50px;
      .container-iframe__datablock {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        button {
          margin: 0 20px 20px 0;
        }
      }
    }
  .container-spinner {
    margin: 0 20px 20px 0;
  }
</style>
