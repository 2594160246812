var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "info-block",
      on: {
        click: function($event) {
          _vm.show = !_vm.show
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "close", class: { open: _vm.show } },
        [
          _c("FontAwesomeIcon", {
            attrs: { icon: _vm.$icons.wallet, "fixed-width": "" }
          }),
          _vm.isAuthorized
            ? _c("strong", [_vm._v("Pré-autorisation effectuée")])
            : _c("strong", [_vm._v("Pas de pré-autorisation effectuée")])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info-icon " },
        [
          _c("FontAwesomeIcon", {
            staticClass: "icon",
            attrs: { icon: _vm.$icons.info }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }