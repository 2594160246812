var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-timer" }, [
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.short,
            expression: "!short"
          }
        ]
      },
      [_vm._v("Durée ")]
    ),
    _vm._v(_vm._s(_vm.elapsedTime) + "\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }