<template>
  <div class="app-timer">
    <span v-show="!short">Durée </span>{{ elapsedTime }}
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'Timer',
    props: {
      short: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        elapsedSeconds: 0,
      };
    },
    mounted() {
      this.$setInterval(() => this.elapsedSeconds += 1, 1000);
    },
    computed: {
      hoursFormat() {
        if (this.elapsedSeconds < 3600) {
          return '';
        }

        return this.short ? 'HH[h]' : 'HH[h ]';
      },
      minutesFormat() {
        if (this.elapsedSeconds < 60) {
          return '';
        }

        return this.short ? 'mm[m]' : 'mm[min ]';
      },
      secondsFormat() {
        return this.short ? 'ss[s]' : 'ss[sec ]';
      },
      elapsedTime() {
        const format = `${this.hoursFormat}${this.minutesFormat}${this.secondsFormat}`.trim();

        return moment.utc(this.elapsedSeconds * 1000).format(format);
      },
    },
  };
</script>
