var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    { attrs: { footer: false, header: false } },
    [
      _vm.isLoaded
        ? _c(
            "main",
            { staticClass: "consultation-page fullscreen" },
            [
              _c("ConsultationToolbar", {
                attrs: {
                  "media-selector": _vm.isStepCalling || _vm.isStepWaitingRoom,
                  timer: _vm.isStepCalling
                }
              }),
              _vm.isStepError
                ? _c(
                    "ErrorInterface",
                    { attrs: { "error-code": _vm.errorCode } },
                    [_vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    ")]
                  )
                : !_vm.isNavigatorOnline
                ? _c(
                    "ErrorInterface",
                    {
                      attrs: {
                        "error-code": "NAVIGATOR_OFFLINE",
                        title: "Aucune connexion internet"
                      }
                    },
                    [_vm._v("\n      En attente de reconnexion...\n    ")]
                  )
                : _vm.isStepPostCalling
                ? _c("PostCallingInterface", {
                    attrs: { "appointment-id": _vm.appointmentId }
                  })
                : _vm.isStepTerminating
                ? _c("AppLoader", [
                    _vm._v("\n      Arrêt de la consultation en cours...\n    ")
                  ])
                : _vm.isStepJoining
                ? _c("AppLoader", [
                    _vm._v(
                      "\n      Démarrage de la consultation en cours...\n    "
                    )
                  ])
                : _vm.isStepWaitingRoom
                ? _c("PreCallingInterface", {
                    attrs: {
                      "patient-full-name": _vm.patientFullName,
                      "patient-phone-number": _vm.patientPhoneNumber,
                      "practitioner-full-name": _vm.practitionerFullName
                    }
                  })
                : _vm.isStepCalling
                ? _c("CallingInterface", {
                    attrs: {
                      "appointment-id": _vm.appointmentId,
                      encounter: _vm.encounter,
                      patient: _vm.appointment.patient,
                      "patient-id": _vm.patientId
                    }
                  })
                : _c("AppLoader", [
                    _vm._v("\n      Chargement en cours...\n    ")
                  ])
            ],
            1
          )
        : _vm._e(),
      _vm.isChatAvailable
        ? [
            !_vm.isStepCalling ? _c("ChatIcon") : _vm._e(),
            _c(
              "AppPanel",
              {
                attrs: { opened: _vm.isChatOpened },
                on: { close: _vm.closeChat }
              },
              [
                _c("SectionTitle", [_vm._v("Chat")]),
                _c("Chat", {
                  attrs: {
                    "appointment-id": _vm.appointmentId,
                    "remote-username": _vm.patientFullName
                  }
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$media.panel && _vm.isChatOpened,
                        expression: "$media.panel && isChatOpened"
                      }
                    ],
                    staticClass: "consultation-camera-local draggable"
                  },
                  [
                    _vm.localVideoStream
                      ? _c("video", {
                          attrs: { autoplay: "" },
                          domProps: { srcObject: _vm.remoteVideoStream }
                        })
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _c("AppConfirmation", {
        ref: "leaveConsultation",
        attrs: {
          title:
            "Souhaitez-vous quitter la téléconsultation ? Vous pourrez reprendre celle-ci plus tard si vous le désirez."
        },
        on: {
          confirm: function(next) {
            return next()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }