<!--
Emitted events:
- close
-->

<template>
  <div class="panel" :class="{ 'panel-telemetry': isPanel(PANEL.TELEMETRY) }" >
      <div class="panel-header" :class="{ 'panel-telemetry--header': isPanel(PANEL.TELEMETRY) }">
      <span @click="setPanel(PANEL.REPORT)" :class="{ active: isPanel(PANEL.REPORT) }">Compte-rendu</span>
      <span @click="setPanel(PANEL.FILES)" :class="{ active: isPanel(PANEL.FILES) }">Documents</span>
      <span @click="setPanel(PANEL.TELEMETRY)" :class="{ active: isPanel(PANEL.TELEMETRY) }">Télémesure</span>
      <span v-if="$media.panel" class="close-button" @click="$emit('close')">
        <FontAwesomeIcon :icon="$icons.closePanel" />
      </span>
    </div>
        <div class="container-drag" :class="{ 'container-drag--report': isPanel(PANEL.REPORT), 'container-drag--telemetry': isPanel(PANEL.TELEMETRY) }">
            <ReportPanel v-if="isPanel(PANEL.REPORT)" :appointment-id="appointmentId" :patient="patient" />
            <FilesPanel
              v-else-if="isPanel(PANEL.FILES)"
              :appointment-id="appointmentId"
              :patient-id="patient.id"
            />
            <TelemetryPanel :encounter="encounter" v-else-if="isPanel(PANEL.TELEMETRY)"  :patient="patient"/>
            <div v-show="$media.panel && this.opened" class="consultation-camera-local draggable">
                <video ref="remoteStream" v-if="remoteVideoStream" autoplay :srcObject.prop="remoteVideoStream" />
            </div>
        </div>
  </div>
</template>

<script>
  import ReportPanel from './ReportPanel';
  import FilesPanel from './FilesPanel';
  import TelemetryPanel from './TelemetryPanel';
  import Draggabilly from 'draggabilly'


  const PANEL = {
    REPORT: 'REPORT',
    TELEMETRY: 'TELEMETRY',
    FILES: 'FILES',
  };

  export default {
    name: 'Panel',
    components: { ReportPanel, FilesPanel, TelemetryPanel },
    props: {
      appointmentId: {
        type: String,
        required: true,
      },
      encounter: {
        type: Object,
        required: true,
      },
      patient: {
        type: Object,
        required: true,
      },
      opened: {
        type: Boolean,
      },
    },
    data() {
      return {
        panel: PANEL.REPORT,
      };
    },
      mounted() {
          let draggableElems = document.querySelectorAll('.draggable');
          let draggies = []
          for ( let draggableElem of draggableElems ) {
              let draggie = new Draggabilly( draggableElem, {
                  containment: true,
              });
              draggies.push( draggie );
          }
      },
      computed: {
      PANEL() {
        return PANEL;
      },
        remoteAudioStream() {
            return this.$store.state.sdk.remote.audio.stream;
        },
        remoteVideoStream() {
            return this.$store.state.sdk.remote.video.stream;
        },
    },
    methods: {
      setPanel(panel) {
        this.panel = panel;
      },
      isPanel(panel) {
        return panel === this.panel;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel {
    background-color: #FFFFFF;
    flex: 3;
    z-index: 1;
    overflow: auto;
    width: 100%;
    padding: 3rem;
    height: 100%;
    @media (max-width: 1279px){
      flex: 100%;
    }

    .form-widget {
      margin-bottom: 2rem;
    }

    .panel-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      font-family: "Gotham Rounded", sans-serif;
      font-size: 1.3em;
      font-weight: lighter;
      margin-bottom: 3rem;
      text-transform: uppercase;
      color: #1F1F1F;
      @include onMobile {
        flex-wrap: nowrap;
      }

      span {
        cursor: pointer;
        position: relative;
        padding: 5px 1.8em;
        display: inline-block;
        @include onMobile {
          padding: 5px 0.7em ;
        }

        &.active {
          font-weight: bold;

          &::after {
            content: "";
            width: calc(100% + 1.8em * 2);
            height: 0.5rem;
            background-color: $mainColor;
            display: block;
            margin: 10px auto 0 -1.8em;
          }
        }
      }
    }

    .close-button {
      margin-left: auto;
      cursor: pointer;
      font-size: 3rem;
      color: #F46868;
      @include onMobile {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

  .panel-container {
    padding: 0 4rem;
    height: 80vh;
    overflow-y: scroll;
  }
  }
  .panel-telemetry {
    @include onMobile {
      padding: 1rem;
    }
    &--header {
      @include onMobile {
        padding: 3rem 3rem 0rem 3rem;
      }
    }
  }
  .container-drag {
    width: 100%;
    height: calc(100vh - 15rem);
    padding: 2rem;
    &--report {
      height: auto;
    }
    &--telemetry {
      @include onMobile {
        padding: 0;
      }
    }
  }
  .draggable {
    @include onDesktop {
      left: 9rem;
      bottom: 2rem;
    }
    width: 12rem;
    height: 14rem;
    border-radius: 10px;
    float: left;
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    z-index: 2;
  }
  .draggable.is-pointer-down {
    z-index: 2;
  }
  .draggable.is-dragging { opacity: 0.7; }
  .consultation-camera-local {
    right: 1.5rem;
    width: 12rem;
    height: 14rem;
    overflow: hidden;
    border-radius: 0.8rem;
    transform: scale(-1, 1);

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>
