<template>
  <div v-if="enabled" class="media-selector">
    <div v-if="hasMultipleAudioDevices" class="media-selector-item">
      <label aria-label="Choix du microphone" for="audio-device">
        <FontAwesomeIcon :icon="$icons.microphone" />
      </label>
      <SelectWrapper>
        <select id="audio-device" v-model="audioDeviceId" aria-labelledby="audio-device">
          <option v-for="(audioDevice, index) in audioDevices" :value="audioDevice.deviceId">
            {{ audioDevice.label || `Micro #${index + 1}` }}
          </option>
        </select>
      </SelectWrapper>
    </div>
    <div v-if="hasMultipleVideoDevices" class="media-selector-item">
      <label aria-label="Choix de la caméra" for="video-device">
        <FontAwesomeIcon :icon="$icons.camera" />
      </label>
      <SelectWrapper>
        <select id="video-device" v-model="videoDeviceId" aria-labelledby="video-device">
          <option v-for="(videoDevice, index) in videoDevices" :value="videoDevice.deviceId">
            {{ videoDevice.label || `Camera #${index + 1}` }}
          </option>
        </select>
      </SelectWrapper>
    </div>
  </div>
</template>

<script>
  import FormWidget from '../Form/FormWidget';
  import SelectWrapper from '../Form/SelectWrapper';

  export default {
    name: 'MediaSelector',
    components: { FormWidget, SelectWrapper },
    computed: {
      hasMultipleAudioDevices() {
        return this.audioDevices.length > 1;
      },
      hasMultipleVideoDevices() {
        return this.videoDevices.length > 1;
      },
      audioDeviceId: {
        get() {
          return this.$store.state.sdk.local.audio.deviceId;
        },
        set(audioDeviceId) {
          this.$store.dispatch('sdkChangeAudioDevice', { deviceId: audioDeviceId });
        },
      },
      videoDeviceId: {
        get() {
          return this.$store.state.sdk.local.video.deviceId;
        },
        set(videoDeviceId) {
          this.$store.dispatch('sdkChangeVideoDevice', { deviceId: videoDeviceId });
        },
      },
      audioDevices() {
        return this.$store.state.sdk.local.audio.devices;
      },
      videoDevices() {
        return this.$store.state.sdk.local.video.devices;
      },
      enabled() {
        return this.$store.getters.sdkShowDeviceSelector
          && (this.hasMultipleAudioDevices || this.hasMultipleVideoDevices);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .media-selector {
    display: flex;
  }

  .media-selector-item {
    display: flex;
    align-items: center;
  }

  label, .select-wrapper select {
    font-size: 1.5rem;
    padding: 1.6rem;
  }

  label {
    padding-right: 0.8rem;
  }

  .select-wrapper select {
    padding-right: 2.6rem;
    padding-left: 0.8rem;
    color: $mainColor;
    border: none;
    background: transparent;
  }

  .media-selector-item {
    display: flex;
    padding-top: 0;
  }
</style>
