var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.remoteLost
    ? _c(
        "div",
        { staticClass: "message-overlay" },
        [
          _c("SectionTitle", { attrs: { light: "", center: "" } }, [
            _vm._v(
              "\n    La connexion avec votre correspondant a été perdue\n  "
            )
          ]),
          _c("p", [
            _vm._v("En attente de reconnexion" + _vm._s(_vm.waitingDots))
          ])
        ],
        1
      )
    : _vm.cameraMuted && _vm.microphoneMuted
    ? _c(
        "div",
        { staticClass: "message-overlay" },
        [
          _c("SectionTitle", { attrs: { light: "", center: "" } }, [
            _vm._v("\n    Le micro et la vidéo ont été coupés\n  ")
          ]),
          _c("p", [
            _vm._v(
              "Vous pouvez les réactiver en cliquant sur la caméra et le micro ci-dessous."
            )
          ])
        ],
        1
      )
    : _vm.cameraMuted
    ? _c(
        "div",
        { staticClass: "message-overlay" },
        [
          _c("SectionTitle", { attrs: { light: "", center: "" } }, [
            _vm._v("\n    La vidéo a été coupée\n  ")
          ]),
          _c("p", [
            _vm._v(
              "Vous pouvez la réactiver en cliquant sur la caméra ci-dessous."
            )
          ])
        ],
        1
      )
    : _vm.microphoneMuted
    ? _c(
        "div",
        { staticClass: "message-overlay" },
        [
          _c("SectionTitle", { attrs: { light: "", center: "" } }, [
            _vm._v("\n    Le micro a été coupé\n  ")
          ]),
          _c("p", [
            _vm._v(
              "Vous pouvez le réactiver en cliquant sur le micro ci-dessous."
            )
          ])
        ],
        1
      )
    : _vm.remoteJoined
    ? _c("div", [
        _vm.patientCameraMuted && _vm.patientMicrophoneMuted
          ? _c(
              "div",
              { staticClass: "message-overlay" },
              [
                _c("SectionTitle", { attrs: { light: "", center: "" } }, [
                  _vm._v(
                    "\n      Le micro et la vidéo de votre correspondant ont été coupés\n    "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    "Votre correspondant a coupé sa caméra ainsi que son micro. Veuillez attendre qu'il les réactive."
                  )
                ])
              ],
              1
            )
          : _vm.patientCameraMuted
          ? _c(
              "div",
              { staticClass: "message-overlay" },
              [
                _c("SectionTitle", { attrs: { light: "", center: "" } }, [
                  _vm._v(
                    "\n      La vidéo de votre correspondant a été coupée\n    "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    "Votre correspondant a coupé sa caméra. Veuillez attendre qu'il la réactive."
                  )
                ])
              ],
              1
            )
          : _vm.patientMicrophoneMuted
          ? _c(
              "div",
              { staticClass: "message-overlay" },
              [
                _c("SectionTitle", { attrs: { light: "", center: "" } }, [
                  _vm._v(
                    "\n      Le micro de votre correspondant a été coupé\n    "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    "Votre correspondant a coupé son micro. Veuillez attendre qu'il le réactive."
                  )
                ])
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }