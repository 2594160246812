<template>
  <div class="info-block" @click="show = !show">
    <div class="close" :class="{'open': show}">
      <FontAwesomeIcon :icon="$icons.wallet" fixed-width />
      <strong v-if="isAuthorized">Pré-autorisation effectuée</strong>
      <strong v-else>Pas de pré-autorisation effectuée</strong>
    </div>
    <div class="info-icon ">
      <FontAwesomeIcon :icon="$icons.info" class="icon"/>
    </div>
  </div>
</template>

<script>

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'InfoStatusPayment',
  components: {FontAwesomeIcon},
  data() {
    return {
      show: false,
    }
  },
  computed: {
    appointment() {
      return this.$store.getters.currentAppointmentWithMeta;
    },
    order() {
      return this.appointment.payload.order;
    },
    isAuthorized() {
      return !!this.order?.transactions.find(transaction => transaction.paymentStatus === 'not_captured');
    },
  },
}
</script>

<style lang="scss" scoped>
.info-icon {
  width: 30px;
  height: 30px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3rem;
  position: absolute;
  left: 100px;
  bottom: 20px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  @include onMobile {
    left:1.8rem
  }
  .info {
    font-size: 1.3rem;
  }
  .icon {
    font-size: 1.5rem;
  }
}

.close {
  max-width: 30px;
  height: 30px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3rem;
  position: absolute;
  left: 100px;
  bottom: 20px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  transition: all .1s ease-in-out;
  @include onMobile {
    left:1.8rem
  }
  &.open {
    max-width: 500px;
    transition: all .1s ease-in-out;
    padding: 0 1rem 0 30px;
    strong {
      padding-left: 0.5rem;
    }
  }
}
</style>