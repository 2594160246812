<template>
  <div class="report-panel">
    <EditProfil :patient="patient" :picture="false" />
    <hr>
    <Observations :patient="patient"/>
    <hr>
    <GroupTitle :icon="$icons.report">Compte-rendu </GroupTitle>
    <p>
      Inutile d’attendre la fin de la téléconsultation pour commencer à rédiger votre compte-rendu ou à établir une
      prescription médicamenteuse.
    </p>
    <FormWidget>
      <label for="medical-observation">Observation médicale</label>
      <AutoHeightTextarea
        id="medical-observation"
        v-model="form.medicalObservation"
      />
      <template v-slot:help>
        Ce champ est uniquement visible par les professionnels de santé.
        Le patient n'aura pas accès à cette information.
      </template>
    </FormWidget>
    <FormWidget>
      <label for="patient-advice">Conseils au patient</label>
      <AutoHeightTextarea
        id="patient-advice"
        v-model="form.patientAdvice"
      />
    </FormWidget>
    <PrescriptionModule
      v-if="!isNurse"
      :encounter-id="encounterId"
      :existing-prescriptions="prescriptions"
      :birth-date="this.beneficiary.birthDate"
    />
  </div>
</template>

<script>
  import EditProfil from '@/components/EditProfil.vue';
  import AutoHeightTextarea from '@/components/Form/AutoHeightTextarea';
  import FormWidget from '@/components/Form/FormWidget';
  import GroupTitle from '@/components/GroupTitle';
  import PrescriptionModule from '@/components/PrescriptionModule';
  import { age } from '@/helpers/format.js';
  import SectionTitle from "@/components/SectionTitle.vue";
  import Observations from "@/components/Consultation/CallingInterface/Observations.vue";
  import moment from "moment/moment";

  export default {
    name: 'ReportPanel',
    components: {Observations, SectionTitle, GroupTitle, PrescriptionModule, AutoHeightTextarea, FormWidget, EditProfil },
    props: {
      patient: {
        type: Object,
        required: true,
      },
      appointmentId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        form: {
          beneficiaryId: this.patient.id,
          medicalObservation: null,
          patientAdvice: null,
          prescription: null,
        },
      };
    },
    mounted() {
      if (this.$hasLocalStorage(this.localStorageKey)) {
        this.form = this.$getLocalStorage(this.localStorageKey);
      }
    },
    beforeDestroy() {
      this.$setLocalStorage(this.localStorageKey, this.form);
    },
    computed: {
      beneficiary() {
        return this.form.beneficiaryId === this.patient.id ? this.patient : this.patient.children.find(
          (child) => child.id === this.form.beneficiaryId,
        );
      },
      beneficiaryAge() {
        return age(this.beneficiary.birthDate);
      },
      encounterId() {
        return this.$store.state.encounters.current.id;
      },
      prescriptions() {
        return this.$store.state.appointments.current.prescriptions;
      },
      localStorageKey() {
        return this.$isPrescriptionHelperEnabled ? `report-ph-${this.appointmentId}` : `report-${this.appointmentId}`;
      },
      isNurse() {
        return this.$store.state.practitioner.profile.type === 'nurse';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .report-panel {
    padding-bottom: 2rem;
  }
</style>
