<template>
  <div class="pre-calling-interface">
    <SectionTitle center light>
      <span v-if="canJoin">Vous pouvez dès à présent appeler votre patient</span>
      <span v-else>Votre patient n'est pas encore connecté</span>
    </SectionTitle>
    <div class="action-wrapper">
      <div class="user-card">
        <UserAvatar :user-name="patientFullName" :src="patientAvatarUrl" />
        <span class="full-name">
          {{ patientFullName }}<br>
          <span class="phone-number" v-if="patientPhoneNumber">{{ patientPhoneNumber | phone }}</span>
        </span>
      </div>
      <hr class="link" :class="{ active: canJoin }">
      <div class="button" :class="{disabled: !canJoin}" @click="handleJoin">
        <FontAwesomeIcon :icon="canJoin ? $icons.encounterStart : $icons.encounterStartDisabled" />
      </div>
      <hr class="link" :class="{ active: canJoin }">
      <div class="user-card">
        <video class="user-avatar" style="object-fit: cover; transform: rotateY(180deg)" autoplay :srcObject.prop="localVideoStream" />
        <span class="full-name">{{ practitionerFullName }}</span>
      </div>
    </div>
    <div class="text-wrapper">
      <div v-if="canJoin" class="patient-is-waiting">
        Votre patient vous attend. Dès que vous êtes prêt, cliquez sur la caméra ci-dessus
        pour lancer la téléconsultation.
      </div>
      <div v-else-if="hasConsultationOccurred" class="terminate-encounter">
        <AppConfirmation
          ref="terminateEncounter"
          title="Êtes-vous sûr de vouloir mettre fin à cette consultation ?"
          @confirm="terminate"
        >
          <template #description>
            Vous serez redirigé vers le rapport de consultation et votre patient ne pourra plus la rejoindre.
          </template>
        </AppConfirmation>

        <ButtonWhite outlined small @click="$confirm($refs.terminateEncounter)">
          Vous avez effectué la consultation via une autre méthode ou votre patient s'est déconnecté ?
          Cliquez ici pour clôturer la consultation
        </ButtonWhite>
      </div>
    </div>
    <InfoStatusPayment/>
  </div>
</template>

<script>
  import SectionTitle from '../SectionTitle';
  import UserAvatar from '../UserAvatar';
  import ButtonRed from '../Button/ButtonRed.vue';
  import ButtonWhite from '../Button/ButtonWhite.vue';
  import MediaSelector from './MediaSelector';
  import AppConfirmation from '@/components/AppConfirmation';
  import InfoStatusPayment from "@/components/InfoStatusPayment";

  export default {
    name: 'PreCallingInterface',
    components: { AppConfirmation, MediaSelector, ButtonWhite, ButtonRed, SectionTitle, UserAvatar, InfoStatusPayment },
    props: {
      patientPhoneNumber: {
        type: String,
        required: true,
      },
      patientFullName: {
        type: String,
        required: true,
      },
      practitionerFullName: {
        type: String,
        required: true,
      },
    },
    computed: {
      canJoin() {
        return this.$store.getters.sdkCanJoin;
      },
      hasConsultationOccurred() {
        return this.$store.state.sdk.hasConsultationOccurred;
      },
      localVideoStream() {
        return this.$store.state.sdk.local.video.stream;
      },
      patientAvatarUrl() {
        return this.$store.state.encounters.current.patient.picture;
      },
    },
    methods: {
      async handleJoin() {
        if (this.canJoin) {
          await this.$store.dispatch('sdkJoin');
        }
      },
      async terminate() {
        await this.$store.dispatch('sdkTerminate');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .pre-calling-interface {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .action-wrapper {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    max-width: 90rem;

    @include onMobile {
      transform: scale(0.7);
      justify-content: space-evenly;
      width: 100%;
    }
  }

  .user-card {
    position: relative;

    video {
      width: 13rem;
      height: 13rem;
    }
  }

  .link {
    border: none;
    background-color: transparent;
    border-top: 0.3rem dashed #EFEFEF;
    flex: 1;

    &.active {
      background-color: #EFEFEF;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
    width: 10rem;
    min-width: 10rem;
    margin: 0 3rem;
    color: #FFFFFF;
    font-size: 4.5rem;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  .hellocare-logo {
    width: 8rem;
    height: 8rem;
    margin: 0 3rem;
  }

  .full-name {
    position: absolute;
    width: 25rem;
    font-family: $titleFont;
    color: #FFFFFF;
    font-size: 2rem;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 3rem;
  }

  .phone-number {
    font-size: 1.6rem;
    opacity: 0.6;
  }

  .patient-is-waiting {
    margin: 7rem auto 0;
    width: 30rem;
    line-height: 1.6;
    color: #FFFFFF;
    text-align: center;
    font-size: 1.5rem;
  }

  .terminate-encounter {
    margin: 14rem auto 0;
    text-align: center;
  }
</style>
