<template>
  <div class="error-interface">
    <SectionTitle center light>
      {{ title }}
    </SectionTitle>
    <div class="text-wrapper">
      <div class="error-message">
        <slot>Une erreur inconnue est survenue</slot>
      </div>
    </div>
    <div v-if="errorCode" class="error-code">
      Code erreur&nbsp;: {{ errorCode }}
    </div>
  </div>
</template>

<script>
  import SectionTitle from '../SectionTitle';
  import ButtonWhite from '../Button/ButtonWhite.vue';

  export default {
    name: 'ErrorInterface',
    components: { ButtonWhite, SectionTitle },
    props: {
      errorCode: {
        type: String,
      },
      title: {
        type: String,
        required: false,
        default: 'Une erreur est survenue'
      },
    },
  };
</script>

<style lang="scss" scoped>
  .error-interface {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .error-message {
    margin: 7rem auto 0;
    max-width: 100rem;
    line-height: 1.6;
    color: #FFFFFF;
    text-align: center;
    font-size: 1.8rem;
  }

  .error-code {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 1.2rem;
    color: #FFFFFF;
    opacity: 0.5;
  }
</style>
