var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.doc2uStarted
        ? _c(
            "div",
            { staticClass: "telemetry-form" },
            [
              _vm._m(0),
              _c("p", [
                _vm._v(
                  "Ce numéro vous sera communiqué par votre patient uniquement si la téléconsultation est effectuée\n      auprès d’un pharmacien agréé."
                )
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.code,
                    expression: "code"
                  }
                ],
                staticClass: "telemetry-input",
                attrs: { type: "text" },
                domProps: { value: _vm.code },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.code = $event.target.value
                  }
                }
              }),
              _c(
                "ButtonGreen",
                {
                  attrs: { rounded: "", small: "" },
                  on: { click: _vm.handleSubmit }
                },
                [
                  _vm._v("Voir les mesures des patients "),
                  _c("FontAwesomeIcon", {
                    attrs: { icon: _vm.$icons.chevronRight }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isLoading ? _c("ModuleLoader") : _vm._e(),
      _vm.telemetry && !_vm.isLoading
        ? _c("div", [
            _vm.telemetry.data
              ? _c(
                  "div",
                  { staticClass: "telemetry-data" },
                  [
                    _c("p", [
                      _c("strong", [_vm._v("Données de santé")]),
                      _c("span", { staticClass: "tag" }, [
                        _c("i", [_vm._v(_vm._s(_vm.lengthTelemetryData))])
                      ])
                    ]),
                    _vm._l(_vm.telemetry.data, function(data, index) {
                      return [
                        _c("div", { staticClass: "biometry-data" }, [
                          _c("img", {
                            attrs: { src: _vm.getPathImg(index), alt: index }
                          }),
                          _c("p", { staticClass: "name-data" }, [
                            _vm._v(_vm._s(_vm.getNameBiometryData(index)))
                          ]),
                          !data.secondValue
                            ? _c("p", { staticClass: "value-data" }, [
                                _vm._v(
                                  _vm._s(data.value) + " " + _vm._s(data.unit)
                                )
                              ])
                            : _vm._e(),
                          data.secondValue
                            ? _c("p", { staticClass: "value-data" }, [
                                _vm._v(
                                  _vm._s(data.value) +
                                    " / " +
                                    _vm._s(data.secondValue) +
                                    " " +
                                    _vm._s(data.unit)
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.telemetry.sounds
              ? _c(
                  "div",
                  { staticClass: "telemetry-data" },
                  [
                    _c("p", [
                      _c("strong", [_vm._v("Sons")]),
                      _c("span", { staticClass: "tag" }, [
                        _c("i", [_vm._v(_vm._s(_vm.lengthTelemetrySounds))])
                      ])
                    ]),
                    _vm.isLoadingFiles
                      ? _c("ModuleLoader")
                      : _vm._l(_vm.file.sounds, function(sound) {
                          return _c(
                            "audio",
                            {
                              staticClass: "telemetry-audio",
                              attrs: { controls: "" }
                            },
                            [_c("source", { attrs: { src: sound } })]
                          )
                        })
                  ],
                  2
                )
              : _vm._e(),
            _vm.telemetry.pictures
              ? _c(
                  "div",
                  { staticClass: "telemetry-data" },
                  [
                    _c("p", [
                      _c("strong", [_vm._v("Photos")]),
                      _c("span", { staticClass: "tag" }, [
                        _c("i", [_vm._v(_vm._s(_vm.lengthTelemetryPictures))])
                      ])
                    ]),
                    _vm.isLoadingFiles
                      ? _c("ModuleLoader")
                      : _vm._l(_vm.file.pictures, function(picture, index) {
                          return _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: picture.src,
                                expression: "picture.src"
                              }
                            ],
                            staticClass: "telemetry-picture",
                            attrs: { alt: "" },
                            on: {
                              click: function($event) {
                                return _vm.openGallery(index)
                              }
                            }
                          })
                        }),
                    _c("LightBox", {
                      ref: "lightbox",
                      attrs: {
                        images: _vm.file.pictures,
                        "show-caption": true,
                        "show-light-box": false
                      }
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.telemetry.movies
              ? _c(
                  "div",
                  { staticClass: "telemetry-data" },
                  [
                    _c(
                      "div",
                      { staticClass: "telemetry-video" },
                      [
                        _c("p", [
                          _c("strong", [_vm._v("Vidéos")]),
                          _c("span", { staticClass: "tag" }, [
                            _c("i", [_vm._v(_vm._s(_vm.lengthTelemetryMovies))])
                          ])
                        ]),
                        _c(
                          "ButtonGrey",
                          {
                            attrs: { small: "", rounded: "" },
                            on: { click: _vm.loadMovies }
                          },
                          [_vm._v("Charger les vidéos")]
                        )
                      ],
                      1
                    ),
                    _vm.isLoadingMovies
                      ? _c("ModuleLoader")
                      : _vm._l(_vm.file.movies, function(movie) {
                          return _c(
                            "video",
                            {
                              staticClass: "telemetry-movie",
                              attrs: { controls: "" }
                            },
                            [_c("source", { attrs: { src: movie } })]
                          )
                        })
                  ],
                  2
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.encounter.doc2uData.room && !_vm.doc2uStarted
        ? _c("div", [
            _c(
              "div",
              { staticClass: "telemetry-form" },
              [
                _vm._m(1),
                _c("p", [
                  _vm._v(
                    "Si votre patient possède un Helloscope ou objet de prise de mesure des constantes compatibles, vous pouvez obtenir ses constantes en cliquant sur le bouton ci-dessous."
                  )
                ]),
                _c(
                  "ButtonGreen",
                  {
                    attrs: { rounded: "", small: "" },
                    on: { click: _vm.toggleDoc2u }
                  },
                  [_vm._v("Lancer la prise de mesure")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.doc2uStarted
        ? _c(
            "div",
            [
              _c("div", { staticClass: "container-iframe-top" }, [
                this.$store.state.observations.isFetching
                  ? _c(
                      "div",
                      { staticClass: "container-spinner" },
                      [_c("Spinner")],
                      1
                    )
                  : _c("div", { staticClass: "container-iframe__datablock" }, [
                      _vm.searchLength > 0
                        ? _c(
                            "div",
                            [
                              _c("LastObservations", {
                                attrs: {
                                  "search-length": _vm.searchLength,
                                  observations: this.$store.state.observations
                                    .search
                                }
                              })
                            ],
                            1
                          )
                        : _c("div", [
                            _c("p", [_vm._v("Aucune mesure enregistrée.")])
                          ])
                    ])
              ]),
              _c("iframe", {
                staticClass: "doc2u-content",
                attrs: {
                  src:
                    "https://app.doc2u.fr/room?t=" +
                    _vm.encounter.doc2uData.token
                }
              }),
              _c(
                "ButtonRed",
                {
                  staticClass: "doc2u-button-finish",
                  attrs: { rounded: "", small: "" },
                  on: { click: _vm.toggleDoc2u }
                },
                [_vm._v("Terminer la prise de mesure")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _vm._v("Code de télémesure Tesly Cloud pour cette téléconsultation.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Helloscope")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }