var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "panel",
      class: { "panel-telemetry": _vm.isPanel(_vm.PANEL.TELEMETRY) }
    },
    [
      _c(
        "div",
        {
          staticClass: "panel-header",
          class: { "panel-telemetry--header": _vm.isPanel(_vm.PANEL.TELEMETRY) }
        },
        [
          _c(
            "span",
            {
              class: { active: _vm.isPanel(_vm.PANEL.REPORT) },
              on: {
                click: function($event) {
                  return _vm.setPanel(_vm.PANEL.REPORT)
                }
              }
            },
            [_vm._v("Compte-rendu")]
          ),
          _c(
            "span",
            {
              class: { active: _vm.isPanel(_vm.PANEL.FILES) },
              on: {
                click: function($event) {
                  return _vm.setPanel(_vm.PANEL.FILES)
                }
              }
            },
            [_vm._v("Documents")]
          ),
          _c(
            "span",
            {
              class: { active: _vm.isPanel(_vm.PANEL.TELEMETRY) },
              on: {
                click: function($event) {
                  return _vm.setPanel(_vm.PANEL.TELEMETRY)
                }
              }
            },
            [_vm._v("Télémesure")]
          ),
          _vm.$media.panel
            ? _c(
                "span",
                {
                  staticClass: "close-button",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [
                  _c("FontAwesomeIcon", {
                    attrs: { icon: _vm.$icons.closePanel }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "div",
        {
          staticClass: "container-drag",
          class: {
            "container-drag--report": _vm.isPanel(_vm.PANEL.REPORT),
            "container-drag--telemetry": _vm.isPanel(_vm.PANEL.TELEMETRY)
          }
        },
        [
          _vm.isPanel(_vm.PANEL.REPORT)
            ? _c("ReportPanel", {
                attrs: {
                  "appointment-id": _vm.appointmentId,
                  patient: _vm.patient
                }
              })
            : _vm.isPanel(_vm.PANEL.FILES)
            ? _c("FilesPanel", {
                attrs: {
                  "appointment-id": _vm.appointmentId,
                  "patient-id": _vm.patient.id
                }
              })
            : _vm.isPanel(_vm.PANEL.TELEMETRY)
            ? _c("TelemetryPanel", {
                attrs: { encounter: _vm.encounter, patient: _vm.patient }
              })
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$media.panel && this.opened,
                  expression: "$media.panel && this.opened"
                }
              ],
              staticClass: "consultation-camera-local draggable"
            },
            [
              _vm.remoteVideoStream
                ? _c("video", {
                    ref: "remoteStream",
                    attrs: { autoplay: "" },
                    domProps: { srcObject: _vm.remoteVideoStream }
                  })
                : _vm._e()
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }