var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", [
        _vm._v(
          "Vous pouvez consulter ou partager des documents avec votre patient depuis cet espace."
        )
      ]),
      _c("p", [
        _vm._v(
          "Les documents sont directement rattachés à la téléconsultation et au dossier patient."
        )
      ]),
      _c("SharedFiles", {
        ref: "sharedFiles",
        attrs: {
          "appointment-id": _vm.appointmentId,
          "patient-id": _vm.patientId
        },
        on: { upload: _vm.handleUploadedFile }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }