<template>
  <div class="post-calling-interface">
    <!-- BAD RATING -->
    <template v-if="rating === 0">
      <FontAwesomeIcon :icon="$icons.question" class="icon" size="4x" />
      <div class="questionnaire">
      <SectionTitle center light>
        Quelles ont été les problèmes ?
      </SectionTitle>
      <FormCheckbox class="text text__checkbox" v-model="sound" :checked="sound">
        <FontAwesomeIcon class="icon icon__checkbox" :icon="$icons.camera"/>
        Je n’entends pas (ou mal) mon interlocuteur
      </FormCheckbox>
      <FormCheckbox class="text text__checkbox" v-model="video" :checked="video">
        <FontAwesomeIcon class="icon icon__checkbox" :icon="$icons.microphone"/>
        Je ne vois pas (ou mal) mon interlocuteur
      </FormCheckbox>
      <AutoHeightTextarea
          v-model="comment"
          placeholder="Informations complémentaires"/>
      <div class="actions">
        <ButtonGreen
            :busy="isRating"
            :icon="$icons.acceptOutlined"
            @click="() => {
              rate(rating, sound, video, comment)
              goToReport()
            }"
            class="action">
          Envoyer
        </ButtonGreen>
      </div>
      </div>
    </template>

<!--    <template v-if="rating === 0">-->
<!--      <FontAwesomeIcon :icon="$icons.thumbsDown" class="icon" size="4x" />-->
<!--      <SectionTitle center light>-->
<!--        Toutes nos excuses-->
<!--      </SectionTitle>-->
<!--      <p class="text">-->
<!--        Nous sommes désolés que vous ayez rencontré des problèmes, nous allons prendre contact avec-->
<!--        vous très prochainement pour en savoir un peu plus.-->
<!--      </p>-->
<!--      <div class="actions single">-->
<!--        <ButtonGreen class="action" @click="goToReport">-->
<!--          Continuer-->
<!--        </ButtonGreen>-->
<!--      </div>-->
<!--    </template>-->

    <!-- GOOD RATING -->
    <template v-else-if="rating === 1">
      <FontAwesomeIcon :icon="$icons.thumbsUp" class="icon" size="4x" />
      <SectionTitle center light>
        Merci !
      </SectionTitle>
      <p class="text">
        Nous sommes ravis que votre consultation se soit bien déroulée. Nous restons à votre écoute
        si vous avez des questions.
      </p>
      <div class="actions single">
        <ButtonGreen class="action" @click="goToReport">
          Continuer
        </ButtonGreen>
      </div>
    </template>

    <!-- NO RATING -->
    <template v-else>
      <FontAwesomeIcon :icon="$icons.thumbsUp" class="icon" size="4x" />
      <SectionTitle center light>
        Consultation terminée !
      </SectionTitle>
      <p class="text">
        Pour améliorer la qualité de ce service, pouvez-vous nous dire si cette consultation en vidéo s'est bien déroulée ?
      </p>
      <div class="actions">
        <ButtonWhite
          :busy="isRating"
          @click="goToForm(0)"
          :icon="$icons.thumbsDown"
          class="action"
          outlined
        >
          J’ai eu des soucis
        </ButtonWhite>
        <ButtonGreen
          :busy="isRating"
          :icon="$icons.thumbsUp"
          @click="rate(1)"
          class="action"
        >
          J’ai le sourire
        </ButtonGreen>
      </div>
    </template>
  </div>
</template>

<script>
  import SectionTitle from '../SectionTitle';
  import ButtonGreen from '../Button/ButtonGreen.vue';
  import ButtonWhite from '../Button/ButtonWhite.vue';
  import FormCheckbox from "@/components/Form/FormCheckbox";
  import AutoHeightTextarea from "@/components/Form/AutoHeightTextarea";

  export default {
    name: 'PostCallingInterface',
    components: {AutoHeightTextarea, FormCheckbox, SectionTitle, ButtonGreen, ButtonWhite },
    props: {
      appointmentId: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        rating: null,
        sound: false,
        video: false,
        comment: "",
        success: ""
      };
    },
    computed: {
      isRating() {
        return this.$store.state.encounters.isRating;
      },
    },
    methods: {
      async rate(rating, sound, video, comment) {
        try {
          await this.$store.dispatch('encountersRate', {
            id: this.$store.state.encounters.current.id,
            rate: rating,
            sound_issue: sound,
            video_issue: video,
            comment_issue: comment
          });
          this.$addSuccess('Formulaire envoyé.');
          this.rating = rating;
          this.video = video;
          this.sound = sound;
          this.comment = comment;
        } catch (e) {
          this.$addError('Une erreur s\'est produite lors de l\'envoi du formulaire.');
          throw e;
        }
      },
      async goToReport() {
        await this.rate()
        this.$router.push({
          name: 'consultation-report',
          params: {appointmentId: this.appointmentId},
          query: {to: this.$route.query.to},
        })
      },
      async goToForm(rating) {
        try {
          await this.$store.dispatch('encountersRate', {
            id: this.$store.state.encounters.current.id,
            rate: rating,
          });
          this.rating = rating;
        } catch (e) {
          this.$addError('Une erreur s\'est produite lors de la notation.');
          throw e;
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .post-calling-interface {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.questionnaire {
      align-items: normal;
      margin: auto;
    }
  }
  ::v-deep .form-checkbox-input:checked ~ .checkbox .checkbox-mark {
    background-color: #FFFFFF;
  }
  ::v-deep .form-checkbox:hover .checkbox-mark {
    background-color: $checkboxCheckedColor;
  }

  .icon {
    color: #FFFFFF;
  }

  .auto-height-textarea {
    resize: none;
    min-height: 10rem;
    width: 100%;
    &:focus {
      border: none;
    }
  }

  .section-title {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .text {
    max-width: 38rem;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    color: #FFFFFF;
    margin: 3rem 0 4rem;
    &__checkbox {
      margin: 1rem 0;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 60rem;

    @include onDesktop {
      flex-direction: row;
    }
  }

  .action {
    margin: 0.5rem 1rem;
    flex: 1;

    .single & {
      flex: none;
    }
  }
</style>
