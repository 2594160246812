<template>
  <div class="calling-interface">
    <div class="video-container" v-show="$media.desktop || !isPanelVisible" :class="{ 'full-width': !isPanelVisible }">
      <div class="cameras">
        <div class="consultation-camera-remote">
          <video ref="remoteStream" v-if="remoteVideoStream" autoplay :srcObject.prop="remoteVideoStream" />
          <audio v-if="remoteAudioStream" autoplay :srcObject.prop="remoteAudioStream" />
        </div>
        <div class="consultation-camera-local">
          <video v-if="localVideoStream" autoplay :srcObject.prop="localVideoStream" />
          <audio v-if="localAudioStream" autoplay :srcObject.prop="localAudioStream" />
        </div>
      </div>

      <MediaMessageOverlay />

      <ControlBar
        :capturing="capturing"
        :is-panel-open="isPanelVisible"
        @capture="capture"
        @togglePanel="isPanelVisible = !isPanelVisible"
      />
    </div>
    <Panel
      v-if="isPanelVisible"
      :opened="isPanelVisible"
      :encounter="encounter"
      :appointment-id="appointmentId"
      :patient="patient"
      @close="isPanelVisible = false"
    />
  </div>
</template>

<script>
  import SectionTitle from '../SectionTitle';
  import MediaMessageOverlay from './CallingInterface/MediaMessageOverlay';
  import ControlBar from './CallingInterface/ControlBar';
  import Panel from './CallingInterface/Panel';
  import moment from 'moment';

  export default {
    name: 'CallingInterface',
    components: { Panel, ControlBar, MediaMessageOverlay, SectionTitle },
    props: {
      patient: {
        type: Object,
        required: true,
      },
      encounter: {
        type: Object,
        required: true,
      },
      appointmentId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        capturing: false,
        isPanelVisible: !this.$media.panel,
      };
    },
    computed: {
      localAudioStream() {
        return this.$store.state.sdk.local.audio.stream;
      },
      localVideoStream() {
        return this.$store.state.sdk.local.video.stream;
      },
      remoteAudioStream() {
        return this.$store.state.sdk.remote.audio.stream;
      },
      remoteVideoStream() {
        return this.$store.state.sdk.remote.video.stream;
      },
      hasMediaSelector() {
        return this.$store.getters.sdkShowDeviceSelector;
      },
    },
    methods: {
      async capture() {
        try {
          this.capturing = true;

          /** @type {HTMLCanvasElement} */
          const canvas = document.createElement('canvas');

          /** @type {HTMLVideoElement} */
          const video = this.$refs.remoteStream;

          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas.getContext('2d').drawImage(video, 0, 0);

          const file = await new Promise(resolve => canvas.toBlob(resolve));
          const date = moment().format('DD.MM.YYYY à HH:mm');

          await this.$store.dispatch('sharedFilesUploadAndAttach', {
            file,
            fileName: `capture du ${date}.png`,
            appointmentId: this.appointmentId,
            patientId: this.patient.id
          });

          this.$store.dispatch('sdkEmitRemoteEvent', { name: '#UPLOADED_FILE' });

          await this.$addSuccess('La capture de la caméra a été effectuée.');

          await this.$store.dispatch('sharedFilesFetchAllByAppointment', { appointmentId: this.appointmentId });
        } catch (e) {
          await this.$addError('Une erreur s\'est produite lors de la capture.')
          throw e;
        } finally {
          this.capturing = false;
        }
      },
    },
    watch: {
      '$media.desktop'(isDesktop) {
        this.isPanelVisible = isDesktop;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .calling-interface {
    display: flex;
    width: 100%;
    max-height: calc(100vh - 7rem - 5rem);
    flex: 1;

    @include onDesktop {
      height: 100%;
      max-height: calc(100vh - 5rem);
    }
  }

  .video-container {
    position: relative;
    max-width: 95rem;
    flex: 5;

    &.full-width {
      max-width: none;
    }
  }

  .cameras {
    position: relative;
    height: 100%;
    background-color: #1F1F1F;
  }

  .consultation-camera-remote {
    height: 100%;

    video {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
  }

  .consultation-camera-local {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    width: 12rem;
    height: 14rem;
    overflow: hidden;
    border-radius: 0.8rem;
    border: 1px solid $thumbnailVideoBorderColor;
    transform: scale(-1, 1);

    @include onDesktop {
      width: 18rem;
      height: 21.5rem;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .restart-devices {
    position: absolute;
    bottom: 0.8rem;
    right: 0.8rem;
    color: white;
    font-size: 2rem;
    cursor: pointer;
  }
</style>
