<template>
  <div v-if="remoteLost" class="message-overlay">
    <SectionTitle light center>
      La connexion avec votre correspondant a été perdue
    </SectionTitle>
    <p>En attente de reconnexion{{ waitingDots }}</p>
  </div>
  <div v-else-if="cameraMuted && microphoneMuted" class="message-overlay">
    <SectionTitle light center>
      Le micro et la vidéo ont été coupés
    </SectionTitle>
    <p>Vous pouvez les réactiver en cliquant sur la caméra et le micro ci-dessous.</p>
  </div>
  <div v-else-if="cameraMuted" class="message-overlay">
    <SectionTitle light center>
      La vidéo a été coupée
    </SectionTitle>
    <p>Vous pouvez la réactiver en cliquant sur la caméra ci-dessous.</p>
  </div>
  <div v-else-if="microphoneMuted" class="message-overlay">
    <SectionTitle light center>
      Le micro a été coupé
    </SectionTitle>
    <p>Vous pouvez le réactiver en cliquant sur le micro ci-dessous.</p>
  </div>
  <div v-else-if="remoteJoined">
    <div v-if="patientCameraMuted && patientMicrophoneMuted" class="message-overlay">
      <SectionTitle light center>
        Le micro et la vidéo de votre correspondant ont été coupés
      </SectionTitle>
      <p>Votre correspondant a coupé sa caméra ainsi que son micro. Veuillez attendre qu'il les réactive.</p>
    </div>
    <div v-else-if="patientCameraMuted" class="message-overlay">
      <SectionTitle light center>
        La vidéo de votre correspondant a été coupée
      </SectionTitle>
      <p>Votre correspondant a coupé sa caméra. Veuillez attendre qu'il la réactive.</p>
    </div>
    <div v-else-if="patientMicrophoneMuted" class="message-overlay">
      <SectionTitle light center>
        Le micro de votre correspondant a été coupé
      </SectionTitle>
      <p>Votre correspondant a coupé son micro. Veuillez attendre qu'il le réactive.</p>
    </div>
  </div>
</template>

<script>
  import SectionTitle from '../../SectionTitle';

  const { REMOTE_STATUS } = window.PlatformSDK;

  let waitingDotsIntervalId;

  export default {
    name: 'MediaMessageOverlay',
    components: { SectionTitle },
    data() {
      return {
        waitingDots: '...',
      };
    },
    computed: {
      remoteJoined() {
        return this.$store.state.sdk.remote.status === REMOTE_STATUS.JOINED;
      },
      remoteLost() {
        return this.$store.state.sdk.remote.status === REMOTE_STATUS.CONNECTION_LOST;
      },
      microphoneMuted() {
        return !this.$store.state.sdk.local.audio.enabled;
      },
      cameraMuted() {
        return !this.$store.state.sdk.local.video.enabled;
      },
      patientMicrophoneMuted() {
        return !this.$store.state.sdk.remote.audio.enabled;
      },
      patientCameraMuted() {
        return !this.$store.state.sdk.remote.video.enabled;
      },
    },
    watch: {
      remoteLost(remoteLost) {
        if (remoteLost) {
          waitingDotsIntervalId = this.$setInterval(() => {
            this.waitingDots += '.';
          }, 2000);
        } else {
          window.clearInterval(waitingDotsIntervalId);
          this.waitingDots = '...';
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .message-overlay {
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: #FFFFFF;
    width: 100%;
    padding: 4rem 4rem 10rem;
    text-align: center;
  }
</style>
